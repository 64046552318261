module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/quickstyle-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"67b5e8d600aca4e54b2d0d9c0e8ce3a0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"yaYUKJ8ke8A9Orc3JpSB4LEwFNiUfGJk","devKey":"yaYUKJ8ke8A9Orc3JpSB4LEwFNiUfGJk","trackPage":true,"trackPageDelay":450,"delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
